:root {

    --rmdp-primary-scale: #325182;
    --rmdp-secondary-scale: #325182;
    --rmdp-shadow-scale: #325182;
    --rmdp-today-scale: #3AACFF;
    --rmdp-hover-scale: #325182;
    --rmdp-deselect-scale: #325182;
  }
  
  .scale-os-calendar-styles * {

  .scale .rmdp-wrapper {
    border: 1px solid var(--rmdp-secondary-scale);
  }
  
  .scale .rmdp-panel-body li {
    background-color: var(--rmdp-primary-scale);
  }
  
  .scale .rmdp-week-day {
    color: var(--rmdp-primary-scale);
  }
  
  .scale .rmdp-day.rmdp-deactive {
    color: var(--rmdp-secondary-scale);
  }
  
  .scale .rmdp-range {
    background-color: var(--rmdp-primary-scale);
  }
  
  .scale .rmdp-arrow {
    border: solid var(--rmdp-primary-scale);
    border-width: 0 2px 2px 0;
  }
  
  .scale .rmdp-arrow-container:hover {
    background-color: var(--rmdp-primary-scale);
  }
  
  .scale .rmdp-panel-body::-webkit-scrollbar-thumb {
    background: var(--rmdp-primary-scale);
  }
  
  .scale .rmdp-day.rmdp-today span {
    background-color: var(--rmdp-today-scale);
  }
  
  .scale .rmdp-rtl .rmdp-panel {
    border-left: unset;
    border-right: 1px solid var(--rmdp-secondary-scale);
  }
  
  .scale .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: var(--rmdp-primary-scale);
  }
  
  .scale .rmdp-day:not(.rmdp-day-hidden) span:hover {
    background-color: var(--rmdp-hover-scale) !important;
  }
  
  .scale .b-deselect {
    color: var(--rmdp-deselect-scale);
    background-color: white;
  }
  
  .scale .rmdp-action-button {
    color: var(--rmdp-primary-scale);
  }
  
  .scale .rmdp-button:not(.rmdp-action-button) {
    background-color: var(--rmdp-primary-scale);
  }
  
  .scale .rmdp-button:not(.rmdp-action-button):hover {
    background-color: var(--rmdp-deselect-scale);
  }
}